<template>
    <section>
        <!-- 2024/04/02 张晓瑜水电转移 -->
        <r-e-dialog title="水电转移" :visible.sync="dialogVisible" show-footer top="10vh" width="550px"
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="tenantData" label-width="100px" size="small" :model="tenantData" :rules="rules">
                <div class="flex">
                    <el-form-item label="当前房间">
                        <el-input v-model="tenantData.name" style="width: 365px;" disabled/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="水费余额">
                        <el-input v-model="tenantData.waterAmount" style="width: 365px;" disabled />
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="电费余额">
                        <el-input v-model="tenantData.electAmount" style="width: 365px;" disabled />
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="转移房间" prop="apartmentUuid" required>
                        <el-select v-model="tenantData.apartmentUuid" style="width: 365px;" placeholder="请选择转移房间">
                            <el-option
                                v-for="(option, index) in apartmentOptions"
                                :key="index"
                                :label="option.label"
                                :value="option.uuid"
                            />
                        </el-select>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>
    </section>
</template>

<script>
import {getContractListByUserAndHouse,} from "@/api/bill";
import {MessageWarning,MessageSuccess} from "@custom/message";
import {SaveWaterElectBanlanceapi} from "@/api/tenant-management";
import {numberFormat} from "@custom";

export default {
    name: "dialog-deposit",
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                billUuid: null,
                contractUuid: null
            },
            rules: {
            // 自定义校验规则
            contractNum: [
                { 
                    validator: (rule, value, callback) => {
                        // 获取押金金额
                        const depoTemporaryBalance = this.tenantData.depoTemporaryBalance;
                        // 转移金额不能少于押金金额
                        if (parseFloat(value) < parseFloat(depoTemporaryBalance)) {
                            callback(new Error('转移金额不能少于押金金额'));
                        } else {
                            callback();
                        }
                    }, 
                    trigger: 'blur' 
                }
            ]
        },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            contractNum: null,
            contractList: [],
            tenantData:{
                apartmentUuid:null
            },
            apartmentOptions:[]
        };
    },
    components: {},
    methods: {
        // openDialog(data) {
            // console.log('122222');
            // const {leasorUuid, apartmentUuid, contract: {uuid: contractUuid, contractNum},uuid} = data;
            // let that = this;
            // const loadingOptions = that.loadingOptions;
            // const loading = that.$loading({...loadingOptions});
            // getContractListByUserAndHouse({leasorUuid, apartmentUuid, contractUuid}).then(res => {
            //     const {list} = res;
            //     if (list.length > 0) {
            //         that.formPublish.billUuid = uuid;
            //         that.contractNum = contractNum;
            //         that.contractList = list;
            //         that.dialogVisible = true;
            //     } else MessageWarning("当前房源没有其他合同,无法转移押金账单", 1000);
            // }).finally(() => loading.close());
        // },
        openDialog(data){
                console.log('data11',data);
                if(data){
                    let {apartment:{name},uuid,waterAmount,electAmount,apartmentUuid,leasorUuid} = data;
                    console.log('apartment:{name},uuid,waterAmount,electAmount',name,uuid,waterAmount,electAmount);
                    this.tenantData = {
                        name,uuid,
                        waterAmount: numberFormat(waterAmount / 100), 
                        electAmount: numberFormat(electAmount / 100) 
                    };
                    const loadingOptions = this.loadingOptions;
                    const loading = this.$loading({...loadingOptions});
                    getContractListByUserAndHouse({leasorUuid, apartmentUuid}).then(res => {
                        console.log('res', res);
                        console.log('res', res.list.apartment);
                        // 提取 list 中每个对象的 apartment 的 name 和 uuid，并保存到一个数组中
                        const apartmentOptions = res.list.map(item => ({
                            label: item.apartment.name,
                            uuid: item.apartment.uuid
                        }));
                        console.log('apartmentOptions',apartmentOptions);

                        // 将提取出的数据保存到组件的数据中，供后续使用
                        this.apartmentOptions = apartmentOptions;
                    }).finally(() => loading.close());
                }
                // if(data){
                //     let {uuid, name, idcard, tel1, type} = data;
                //     this.tenantData = {uuid, name, idcard, tel1, type}
                //     console.log('uuid',uuid);
                // }
                // let {uuid} = this.tenantData;
                // const loadingOptions = this.loadingOptions;
                // const loading = this.$loading({...loadingOptions});
                // getDepositAmountapi({uuid}).then(res => {
                //         // MessageSuccess('转移成功');
                //         console.log('res',res);
                //         this.clickCancel();
                //     }).finally(() => loading.close());
                this.dialogVisible=true;
            },
        clickSubmit() {
            let that = this;
            this.$refs["tenantData"].validate((valid) => {
                if (valid) {
                    // const {billUuid,contractUuid} = that.formPublish;
                    let {uuid,apartmentUuid } = that.tenantData;
                    console.log('提交',that.tenantData);
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    SaveWaterElectBanlanceapi({banlanceUuid:uuid,apartmentUuid}).then(res => {
                        console.log('res',res);
                        MessageSuccess('转移成功');
                        that.clickCancel();
                        that.$emit("handleSearch");
                    }).finally(() => loading.close());
                }
            });
        },
        async clickCancel() {
            this.tenantData =  {uuid: null, waterAmount: null,electAmount:null,apartmentUuid:null};
            await this.$emit('clickCancel');
            this.dialogVisible = false;
        },
    },
    created() {
    },
}
</script>

<style scoped>

</style>